<template>
	<div class="dialog">
		<modal-dialog :actions="actions" :is-open="true" title="" :close-text="closeText" @confirm="confirm" @close="close">
			<p style="margin-bottom: 0;">
				{{ message }}
			</p>
			<ast-checkbox v-if="isNagOptOutVisible" v-model="hideNag" style="margin: 16px 0 0 0; font-size: 0.9em;">
				In the future, do not warn me about extra fees for attachments.
			</ast-checkbox>
		</modal-dialog>
	</div>
</template>

<script>
import api from '@services/api'
import { dlg } from '@utils/dlgUtils'
import { uploadData } from '@services/uploads'
import { fileSize } from '@/filters'
import ModalDialog from '@components/ModalDialog.vue'
import AstCheckbox from '@components/Checkbox.vue'

const AttachmentFileSizeDlg = {
	name: 'AttachmentFileSizeDlg',
	components: {
		AstCheckbox,
		ModalDialog,
	},
	props: {
		message: {
			type: String,
			required: true,
		},
		actions: {
			type: Array,
			default: () => [],
		},
		closeText: {
			type: String,
			default: 'Close',
		},
		isNagOptOutVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			hideNag: false, // TODO
		}
	},
	methods: {
		close() {
			dlg.close(this, true, false)
		},
		confirm() {
			if (this.isNagOptOutVisible && this.hideNag) {
				api.user.setUserSetting('DisableStudyAttachmentNag', 'Study', true)
			}
			dlg.close(this, true, true)
		},
	},
}

export default AttachmentFileSizeDlg

export async function confirmAttachmentFileSize(currentTotalBytes, files) {
	const alreadyExceededMaxFree = currentTotalBytes > uploadData.MAX_FREE_ATTACHMENT_SPACE
	const newTotalBytes = currentTotalBytes + files.reduce((a, file) => a + file.size, 0)
	const fileOrFiles = files.length === 1 ? 'file' : 'files'

	if (newTotalBytes > uploadData.MAX_ATTACHMENT_SPACE) {
		return showExceedsMaxWarning()
	}

	if (!alreadyExceededMaxFree && newTotalBytes > uploadData.MAX_FREE_ATTACHMENT_SPACE) {
		const hideNag = await api.user.getUserSetting('DisableStudyAttachmentNag', 'Study').then(r => r && r.data)
		if (hideNag !== true) return showExceedsMaxFreePrompt()
	}

	return true

	function showExceedsMaxWarning() {
		const max = fileSize(uploadData.MAX_ATTACHMENT_SPACE)
		const newTotal = fileSize(newTotalBytes)
		const message = `The requested ${fileOrFiles} cannot be attached to this study.  Adding the specified
			${fileOrFiles} would result in a total attachment size of ${newTotal}. A maximum of ${max} of
			non-image files may be attached to a study.`
		return dlg.open(AttachmentFileSizeDlg, { message }, false)
	}

	function showExceedsMaxFreePrompt() {
		const maxFree = fileSize(uploadData.MAX_FREE_ATTACHMENT_SPACE)
		const message = `By attaching the selected ${fileOrFiles}, you will be exceeding the complimentary
			threshold of ${maxFree} of non-image files per study. If you continue, an additional one-time
			fee will be added to your next monthly invoice, in the amount of your regular study fee.`
		const actions = [
			{
				type: 'button',
				name: 'OK',
				emit: 'confirm',
			},
		]
		const isNagOptOutVisible = true
		const closeText = 'Cancel'
		return dlg.open(AttachmentFileSizeDlg, { message, actions, isNagOptOutVisible, closeText }, false)
	}
}
</script>
