<template>
	<modal-wrapper :close-on-escape="false" @close="close">
		<!-- Entire modal is drop target if only image uploads are available -->
		<div class="import-studies" :class="{ 'has-uploads': uploads.length || excludedFiles.length }">
			<dlg-header class="import-studies-header" title="Import Studies" @close="close" />

			<div ref="dropTarget" class="import-studies-body">
				<!-- Image Uploads -->
				<div class="import-actions">
					<upload-form
						class="modal-upload-form"
						context="study-import"
						:drop-target="dropTarget"
						:allow-dicoms="true"
						:allow-jpegs="false"
						:allow-attachments="false"
						:is-whole-form-clickable="false"
					>
						<template #default="{ browseMessage, canUploadFolder, clickFileInput }">
							<svg-icon :icon="canUploadFolder ? 'folder-dcm' : 'file-upload'" style="font-size: 2em;" />
							<div style="flex-shrink: 1;">
								<p style="font-style: italic; font-size: 0.7em;">
									Have DICOM files?
								</p>
								Drop files here or
								<a @click="clickFileInput">{{ browseMessage }}</a>
							</div>
						</template>
					</upload-form>
					<div class="create-study-option">
						<svg-icon icon="file-multiple" style="font-size: 2em; color: var(--icon-default); margin-right: 16px;" />
						<div>
							<p style="font-style: italic; font-size: 0.7em;">
								Have non-DICOM files?
								<small style="font-size: 0.75em; font-style: normal; padding-left:0.5ch;">
									(JPEG, PNG, PDF, etc.)
								</small>
							</p>
							<a style="border-bottom: 1px solid currentColor;" @click.prevent="createStudy">
								Create study from files
							</a>
						</div>
					</div>
				</div>
				<div v-if="uploads.length || excludedFiles.length" class="upload-items">
					<!-- Excluded Files -->
					<transition name="expand">
						<ol v-if="excludedFiles.length" class="excluded-files">
							<li is="UploadItemFile" v-for="(file, i) in excludedFiles" :key="i" :upload="file">
								<template slot="jpeg-error">
									JPEG and PNG images are excluded from DICOM import. To import this file,
									<a style="text-decoration: underline;" @click.prevent="createStudy">
										create a study from non-DICOM files.
									</a>
								</template>
							</li>
							<!-- Clear excluded files button -->
							<ast-button class="close" @click.native="clearExcludedFiles('study-import')">
								<svg-icon icon="close" />
							</ast-button>
						</ol>
					</transition>
					<!-- Studies -->
					<transition-group name="expand">
						<upload-item-batch
							v-for="batchId in batchIds"
							:key="batchId ? batchId : 'jpeg-form'"
							:uploads="getUploadsInBatch(batchId)"
							:show-view-button="true"
							:show-rename-button="true"
							@remove-batch="removeBatch"
						/>
					</transition-group>
				</div>
				<div class="drop-indicator">
					<svg-icon icon="cloud-upload" />
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import AstButton from '@components/Button'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper'
import UploadForm from '@components/UploadForm'

import { dlg } from '@utils/dlgUtils'
import { openCreateStudyDlg } from '@dialogs/CreateStudy.vue'
import { uploadData } from '@services/uploads'

const ImportStudies = {
	name: 'ImportStudies',
	components: {
		AstButton,
		DlgHeader,
		ModalWrapper,
		UploadForm,
		UploadItemBatch: () => import(/* webpackChunkName: "componentUploadItemBatch" */ '@components/UploadItemBatch'),
		UploadItemFile: () => import(/* webpackChunkName: "componentUploadItemFile" */ '@components/UploadItemFile'),
	},
	data() {
		return {
			dropTarget: undefined,
			uploadData,
		}
	},
	computed: {
		excludedFiles() {
			return this.uploadData.excludedFiles.filter(upload => upload.context === 'study-import')
		},
		uploads() {
			return this.uploadData.uploads.filter(upload => upload.context === 'study-import')
		},
		batchIds() {
			let batchIds = this.uploadData.uploads.filter(u => u.context === 'study-import' && u.batchId).map(u => u.batchId)
			return [...new Set(batchIds)]
		},
	},
	mounted() {
		this.dropTarget = this.$refs.dropTarget
	},
	methods: {
		async createStudy() {
			const isCreatingStudy = await openCreateStudyDlg()
			if (!isCreatingStudy) return
			uploadData.CHANGE_UPLOAD_CONTEXT({ from: 'create-study', to: 'study-import' })
		},
		clearExcludedFiles() {
			uploadData.CLEAR_UPLOAD_EXCLUDED_FILES()
		},
		getUploadsInBatch(batchId) {
			return this.uploads.filter(upload => upload.batchId === batchId || (!upload.batchId && !batchId))
		},
		async removeBatch({ studyId, batchId }) {
			uploadData.stopUploads({ batchId })
		},
		close() {
			dlg.close(this)
		},
	},
}

export default ImportStudies

export function openImportStudies() {
	return dlg.open(ImportStudies, {})
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.import-studies {
	display: flex;
	position: relative;
	flex-direction: column;
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	width: 90vw;
	max-width: 1000px;
	height: 50vh;
	overflow: hidden;
	transition: height 0.2s ease;
	.import-actions {
		display: inline-flex;
		flex-direction: column;
		margin: auto;
		padding-bottom: 1.75em;
		.modal-upload-form,
		.create-study-option {
			padding: 20px 16px;
			flex-grow: 1;
			justify-content: flex-start;
		}
		.modal-upload-form {
			border: 0;
			.drop-or-select-message {
				flex-wrap: nowrap;
			}
		}
		.create-study-option {
			display: flex;
			align-items: center;
			font-size: 1.75em;
		}
	}
	&.has-uploads {
		height: 90vh;
		.import-actions {
			@media (min-width: $mqSmall) {
				flex-direction: row;
				.modal-upload-form,
				.create-study-option {
					justify-content: center;
					width: 50%;
				}
			}
			display: flex;
			margin: 0;
			font-size: 0.75em;
			padding-bottom: 0;
			background: var(--secondary-bg);
			border-bottom: 1px solid var(--secondary-border);
		}
	}
	@media (max-width: $mqSmall) {
		height: 100vh;
		height: -webkit-fill-available;
		width: 100vw;
		.import-actions,
		&.has-uploads .import-actions {
			font-size: 0.7em;
		}
	}
	.import-studies-header {
		flex-shrink: 0;
		border-bottom: 1px solid var(--card-border);
	}
	.import-studies-body {
		flex-grow: 1;
		background: var(--primary-bg);
		overflow: auto;
		display: flex;
		flex-direction: column;
		.drop-indicator {
			display: none;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			opacity: 0.75;
			font-size: 5em;
			background: var(--tertiary-bg);
		}
		&.is-dragging {
			.drop-indicator {
				display: flex;
			}
		}
	}
	.modal-upload-form {
		padding: 40px;
		&.upload {
			background: transparent;
		}
	}
	.attachment-study-form {
		background: var(--secondary-bg);
		border: 1px solid var(--secondary-border);
		padding: 15px 30px 15px 15px;
	}
	.upload-items {
		padding: 30px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		flex-grow: 1;
		min-height: 0;
		background: var(--primary-bg);
		.study {
			margin: 15px auto;
		}
		ol {
			list-style-type: none;
			margin: auto;
			position: relative;
			button.close {
				position: absolute;
				top: 0;
				right: 0;
				padding: 15px;
				background: transparent;
				border: 0;
			}
		}
	}
}
</style>
