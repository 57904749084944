<template>
	<transition name="fade">
		<!--Wrapper div for inline content-->
		<div v-if="isVisible">
			<div class="banner" :class="[{ 'is-rounded': isRounded }, `banner-${type}`]">
				<span v-if="isClosable" class="close-button" @click="close">&times;</span>
				<svg-icon icon="info-circle" />
				<div class="content">
					<div class="message">
						<slot>Insert banner message here</slot>
					</div>
					<slot name="learn-more">
						<a class="learn-more" :href="learnMoreLink" target="hidden-iframe" @click="learnMore">
							Learn more.
						</a>
					</slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import storage from '@services/storage'

export default {
	name: 'Banner',
	props: {
		id: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'default',
		},
		learnMoreLink: {
			type: String,
			default: '',
		},
		isClosable: {
			type: Boolean,
			default: false,
		},
		isRounded: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isVisible: true,
		}
	},
	computed: {
		bannerId() {
			return this.id && `${this.id}-banner`
		},
	},
	created() {
		// Don't open this banner if any banner with this id was already closed
		if (this.isClosable && storage.getItem(this.bannerId)) {
			this.close()
		}
	},
	methods: {
		learnMore(e) {
			this.$emit('learn-more')
			if (!this.learnMoreLink) {
				e.preventDefault()
			}
		},
		close() {
			this.isVisible = false
			// Don't open this banner again if id is assigned
			if (this.id) {
				storage.setItem(this.bannerId, true)
			}
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
.banner {
	position: relative;
	display: inline-flex;
	align-items: flex-start;
	flex-wrap: nowrap;
	color: var(--secondary-label);
	background: var(--secondary-bg);
	border-width: 1px 0 0 0;
	border-style: solid;
	border-color: var(--divider);
	padding: 6px 16px 16px 16px;

	&.banner-info {
		color: var(--banner-info-color);
		background: var(--banner-info-bg);
		border-color: var(--banner-info-border);
	}

	&.is-rounded {
		border-width: 1px;
		border-radius: 5px;
	}

	.icon {
		font-size: 1.4em;
		margin-top: 8px;
		margin-right: 10px;
	}

	.content {
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.message {
		margin-top: 10px;
		margin-right: 10px;
	}

	.learn-more {
		display: inline-block;
		color: inherit;
		text-decoration: underline;
		margin-top: 10px;
		margin-right: 10px;
	}

	.close-button {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 2px;
		right: 7px;
		font-weight: bold;
		cursor: pointer;
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
