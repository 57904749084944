<template>
	<modal-wrapper @close="close()">
		<div class="dialog" style="width:400px">
			<dlg-header title="About Community" @close="close" />
			<div style="padding: 16px;">
				<p style="margin-bottom: 35px;">
					Asteris Community is a modern progressive web application (PWA). Below is a brief summary
					of information that may be helpful for diagnostic purposes:
				</p>

				<ast-data-table :is-hoverable="false" :is-striped="true" :is-condensed="true">
					<tbody>
						<tr>
							<td colspan="2" class="title-row">Community</td>
						</tr>
						<tr>
							<td>Release:</td>
							<td>{{ release }}</td>
						</tr>
						<tr>
							<td colspan="2" class="title-row">Browser</td>
						</tr>
						<tr>
							<td>Name:</td>
							<td>{{ browser.name }}</td>
						</tr>
						<tr>
							<td>Version:</td>
							<td>{{ browser.version }}</td>
						</tr>
						<tr>
							<td>OS:</td>
							<td>{{ browser.os }}</td>
						</tr>
					</tbody>
				</ast-data-table>
			</div>
			<community-upsell-promo />
		</div>
	</modal-wrapper>
</template>

<script>
import { detect } from 'detect-browser'
import AstDataTable from '@components/DataTable'
import CommunityUpsellPromo from '@components/CommunityUpsellPromo'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'

const AboutCommunityDlg = {
	name: 'AboutCommunity',
	components: {
		AstDataTable,
		CommunityUpsellPromo,
		DlgHeader,
		ModalWrapper,
	},
	data() {
		return {
			release: window.config.release,
			// browser.name
			// browser.version
			// browser.os
			browser: {},
		}
	},
	created() {
		const browser = detect()
		if (browser) {
			this.browser = browser
		}
	},
	methods: {
		close() {
			dlg.close(this)
		},
	},
}

export default AboutCommunityDlg

export function openAboutCommunityDlg() {
	return dlg.open(AboutCommunityDlg)
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.dialog {
	padding: 0;
}

.title-row {
	background: var(--card-header-info-bg) !important;
	color: var(--card-header-info-color) !important;
}
</style>
