<template>
	<div class="navigation-items">
		<div class="button-container">
			<button v-if="isCommunityUser" class="btn btn-primary btn-big" @click="startRequest">
				<svg-icon icon="paper-plane" />
				Start Consultation
			</button>
			<button
				v-else-if="permissions.importData && !isRepositoryUser && !isCommunityUser"
				class="btn btn-big"
				:class="isUploading('study-import') ? 'btn-success' : 'btn-primary'"
				@click="importStudies"
			>
				<svg-icon icon="cloud-upload" fixed />
				<span v-if="isUploading('study-import')"> Uploading {{ uploadPercent('study-import') }}% </span>
				<span v-else>
					Import Studies
					<svg-icon v-if="isUploadError('study-import')" icon="exclamation-circle" class="is-danger" fixed />
				</span>
			</button>
		</div>
		<ul>
			<li
				v-for="(link, i) in links"
				:key="i"
				:data-test="`nav-${link.name.toLowerCase()}`"
				@click="!!link.onClick && link.onClick()"
			>
				<!-- LINKS -->
				<router-link v-if="link.to" :to="link.to">
					<svg-icon v-if="link.iconName" :icon="link.iconName" />
					<span>{{ link.name }}</span>
					<button
						v-if="link.menu"
						class="configure-menu"
						:title="`Configure ${link.name}`"
						@click.stop.prevent="configureMenuSettings(link.menu)"
					>
						<svg-icon icon="settings" />
					</button>
				</router-link>
				<!-- Child links -->
				<template v-for="(child, j) in link.children">
					<router-link
						:key="j"
						:to="child.to"
						class="child-link"
						:class="{
							'drop-target': child.isDropTarget && studyFolders.dragStudyId,
							[`nav-${link.name.toLowerCase().replace(/\s+/g, '')}`]: `nav-${link.name
								.toLowerCase()
								.replace(/\s+/g, '')}`,
						}"
						:data-test="
							`nav-${link.name.toLowerCase().replace(/\s+/g, '')}-${child.name.toLowerCase().replace(/\s+/g, '')}`
						"
						exact
						@dragover.native.prevent="onDragOver(child, $event)"
						@dragleave.native.prevent="onDragLeave"
						@drop.native.prevent="onDrop(child)"
					>
						<span>
							<svg-icon v-if="child.iconName" :icon="child.iconName" style="font-size: 1em; opacity: 0.5;" />
							{{ child.name }}
						</span>
						<span class="count">{{ folderCounts[child.folder] }}</span>
					</router-link>
					<router-link
						v-for="(grandchild, k) in child.children"
						:key="j + '-' + k"
						:to="grandchild.to"
						class="child-link grandchild"
						:class="{ 'drop-target': grandchild.isDropTarget && studyFolders.dragStudyId }"
						exact
						@dragover.native.prevent="onDragOver(grandchild, $event)"
						@dragleave.native.prevent="onDragLeave"
						@drop.native.prevent="onDrop(grandchild)"
					>
						<span>
							<svg-icon v-if="grandchild.iconName" :icon="grandchild.iconName" style="font-size: 1em; opacity: 0.5;" />
							{{ grandchild.name }}
						</span>
						<span class="count">{{ folderCounts[grandchild.folder] }}</span>
					</router-link>
				</template>
				<!-- Folder collapse/expand -->
				<a
					v-if="link.collapsibleChildren && studyFolders.folders.length > 6"
					class="child-link"
					style="font-size: 0.9em;"
					@click="toggleFolderCollapse"
				>
					<span v-if="isHidingFolders">
						<svg-icon icon="caret-down" style="font-size: 1em; opacity: 0.5;" />
						Show all folders
					</span>
					<span v-else>
						<svg-icon icon="caret-up" style="font-size: 1em; opacity: 0.5;" />
						Show fewer folders
					</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { openImportStudies } from '@dialogs/ImportStudies.vue'
import { communityService } from '@services/communityService'
import { menu } from '@services/menu'
import { eventBus } from '@services/eventBus'
import { uploadData } from '@services/uploads'
import { studyFolders } from '@services/folders'
import { openSettings } from '@/dialogs/Settings.vue'

import { statusMap as reviewStatusMap } from '@/router/views/RepositoryReview/shared'
// TODO: REMOVE PRIOR TO RELEASE
import { list as testHagyardData } from '@/router/views/RepositoryReview/testData.js'

const submissionStats = testHagyardData.reduce((acc, d) => {
	acc[d.status] = acc[d.status] ? acc[d.status] + 1 : 1
	return acc
}, {})

export default {
	name: 'ListNavigationItems',
	data() {
		return {
			menu,
			folderCounts: {},
			shouldCollapseFolders: true,
			pollingTimer: undefined,
			uploadData,
			studyFolders,
		}
	},
	computed: {
		...mapState({
			consultants: state => state.static.consultants,
			claims: state => state.auth.claims,
			permissions: state => state.static.permissions,
			showTeleconsultationScheduleFiltering: state => state.static.permissions.showTeleconsultationScheduleFiltering,
		}),
		...mapGetters(['isConsultantUser', 'isRepositoryUser', 'isCommunityUser']),
		visibleFolders() {
			const rootFolders = studyFolders.folders.filter(f => !f.parentFolderId)
			const sortedFolders = []
			rootFolders.forEach(f => {
				sortedFolders.push(f)
				const childFolders = studyFolders.folders.filter(c => c.parentFolderId === f.id)
				sortedFolders.push(...childFolders)
			})
			if (this.shouldCollapseFolders && sortedFolders.length > 6) {
				return sortedFolders.slice(0, 5)
			}
			return sortedFolders
		},
		isHidingFolders() {
			return this.visibleFolders.length < studyFolders.folders.length
		},
		links() {
			const folderToMenuItem = f => ({
				id: f.id,
				name: f.name,
				to: `/studies?filter-preset=${f.id}`,
				iconName: 'folder-outline',
				isDropTarget: true,
				children: this.visibleFolders.filter(c => c.parentFolderId === f.id).map(folderToMenuItem),
			})
			let links = [
				{
					name: 'Studies', // Non-Repository, Non-Community
					onClick: () => this.$store.commit('SET_CURRENTPAGE', { route: 'studies', page: 0 }),
					to: this.$route.name === 'studies' ? this.$route.fullPath : '/studies',
					iconName: 'book',
					isVisible: !this.isRepositoryUser && !this.isCommunityUser,
					menu: 'studies',
					collapsibleChildren: true,
					children: [
						...[
							{
								name: 'Today',
								to: '/studies?filter-preset=today',
							},
							{
								name: 'Yesterday',
								to: '/studies?filter-preset=yesterday',
							},
							{
								name: 'Past Week',
								to: '/studies?filter-preset=week',
							},
							{
								name: 'Past Month',
								to: '/studies?filter-preset=month',
							},
						].map(m => ({ ...m, iconName: 'magnify' })),
						...this.visibleFolders.filter(f => !f.parentFolderId).map(folderToMenuItem),
					],
				},
				{
					name: 'Studies', // Repository
					to: '/consigner-studies',
					iconName: 'book',
					isVisible: this.isRepositoryUser,
				},
				{
					name: 'Repository Review', // Hagyard Test
					to: '/repository-review',
					iconName: 'inbox-download',
					isVisible: this.permissions.reviewSubmissionViewer,
					children: [
						{
							name: 'Dashboard',
							to: '/repository-review/dashboard',
							iconName: 'table-solid',
						},
					],
				},
				{
					name: 'Teleconsultation', // Omni Consultants
					onClick: () => this.$store.commit('SET_CURRENTPAGE', { route: 'teleconsultations', page: 0 }),
					to: this.$route.name === 'teleconsultations' ? this.$route.fullPath : '/teleconsultations',
					iconName: 'user-md',
					isVisible: this.isConsultantUser && !this.isRepositoryUser,
					menu: 'teleconsultation',
					children: this.menu.menuItems.teleconsultation
						.filter(m => m.isVisible)
						.map(m => ({ ...m, iconName: 'magnify' })),
				},
				{
					name: 'Image Uploads', // Community
					to: '/teleconsultation-image-uploads',
					iconName: 'folder-upload',
					isVisible: this.isCommunityUser,
					children: [
						{
							name: 'Not Sent',
							to: '/teleconsultation-image-uploads?filter-preset=not_sent',
							folder: 'community_notSent',
						},
						{
							name: 'Today',
							to: '/teleconsultation-image-uploads?filter-preset=today',
							folder: 'community_today',
						},
						{
							name: 'Yesterday',
							to: '/teleconsultation-image-uploads?filter-preset=yesterday',
							folder: 'community_yesterday',
						},
					],
				},
				{
					name: 'Teleconsultation', // Omni Clients / Community
					onClick: () => this.$store.commit('SET_CURRENTPAGE', { route: 'teleconsultations', page: 0 }),
					to: this.$route.name === 'teleconsultations' ? this.$route.fullPath : '/teleconsultations',
					iconName: 'user-md',
					isVisible: this.consultants.length && !this.isConsultantUser && !this.isRepositoryUser,
					children: [
						{
							name: 'Open',
							to: '/teleconsultations/?filter-preset=ConsultantOpenAll',
							folder: 'teleconsultations_ConsultantOpenAll',
						},
						{
							name: 'Today',
							to: '/teleconsultations/?filter-preset=ConsultantToday',
							folder: 'teleconsultations_ConsultantToday',
						},
						{
							name: 'Yesterday',
							to: '/teleconsultations/?filter-preset=ConsultantYesterday',
							folder: 'teleconsultations_ConsultantYesterday',
						},
						{
							name: 'Complete',
							to: '/teleconsultations/?filter-preset=complete',
						},
					].map(m => ({ ...m, iconName: 'magnify' })),
				},
				{
					name: 'Schedule',
					to: '/schedule/?date=today',
					isVisible: this.claims.isSchedulingEnabled && !this.isRepositoryUser && !this.isCommunityUser,
					iconName: 'calendar',
					children: [
						{
							name: 'Today',
							to: '/schedule/?date=today',
						},
						{
							name: 'Yesterday',
							to: '/schedule/?date=yesterday',
						},
						{
							name: 'Tomorrow',
							to: '/schedule/?date=tomorrow',
						},
					].map(m => ({ ...m, iconName: 'magnify' })),
				},
			]
			const repositoryReviewLinks = reviewStatusMap.map((status, index) => ({
				name: status.label,
				to: '/repository-review?filter-preset=' + status.value,
				folder: index,
				iconName: 'magnify',
			}))
			links.find((link, index) => {
				if (link.name === 'Repository Review') {
					links[index].children = [...repositoryReviewLinks, ...links[index].children]
				}
			})
			links = links.filter(l => l.isVisible)
			const linkOrder = this.menu.menuItems.root
			if (!this.isCommunityUser && !this.isRepositoryUser && linkOrder) {
				links.sort((a, b) => {
					const aIndex = linkOrder.indexOf(a.name)
					const bIndex = linkOrder.indexOf(b.name)
					// if link is missing from setting, place on bottom
					if (aIndex !== -1 && bIndex === -1) return -1
					if (aIndex === -1 && bIndex !== -1) return 1
					// order based on settings
					if (aIndex < bIndex) return -1
					if (aIndex > bIndex) return 1
					return 0
				})
			}
			return links
		},
		showToggleFolderCollapse() {
			if (this.collapsedFolderIds.length) return true
			return this.isDrawerOverflowing && this.allFolderIds.length > 2
		},
	},
	watch: {
		links() {
			this.getFolderCounts()
		},
		'$route.name'(route, previousRoute) {
			// refresh folder counts when leaving a request-submit-* route
			if (previousRoute.includes('request-submit')) this.getFolderCounts()
		},
		consultants: {
			handler() {
				this.getFolderCounts()
			},
			immediate: true,
		},
	},
	mounted() {
		this.pollingTimer = setInterval(this.getFolderCounts, 60000)
	},
	beforeDestroy() {
		clearInterval(this.pollingTimer)
	},
	methods: {
		toggleFolderCollapse() {
			this.shouldCollapseFolders = !this.shouldCollapseFolders
		},
		isUploadError(context) {
			return this.uploadData.isUploadError(context)
		},
		isUploading(context) {
			return this.uploadData.isUploading(context)
		},
		uploadPercent(context) {
			return this.uploadData.uploadPercent(context)
		},
		onDragOver(folder, e) {
			if (!folder.isDropTarget) {
				e.dataTransfer.dropEffect = 'none'
				return
			}
			e.dataTransfer.dropEffect = 'move'
			e.target.classList.add('drag-over')
		},
		onDragLeave(e) {
			e.target.classList.remove('drag-over')
		},
		onDrop(folder) {
			if (!studyFolders.dragStudyId || !folder.isDropTarget) return
			studyFolders.addStudyToFolder(studyFolders.dragStudyId, folder.id).then(() => {
				this.$notifications.addSuccess(`The study was added to the ${folder.name} folder!`)
			})
		},
		async importStudies() {
			const uploadedStudyIds = await openImportStudies()
			if (uploadedStudyIds && uploadedStudyIds.length) eventBus.post('refresh-study-list')
		},
		startRequest() {
			this.$router.push('/teleconsultation-request')
		},
		async getFolderCounts() {
			let counts = {}
			let requests = []

			// TODO: fix performance of folder counts
			// https://app.clubhouse.io/asteris/story/7018/remove-teleconsultation-folder-count
			// https://app.clubhouse.io/asteris/story/6971/timeout-error-for-teleconsultation-count-api-calls

			if (this.isCommunityUser) {
				requests.push(
					communityService.getFolderCounts().then(data => {
						for (let prop in data) {
							counts[`community_${prop}`] = data[prop]
						}
					})
				)
			}
			// TODO: REMOVE THIS TEST DATA
			else {
				const { submitted, ...temp } = submissionStats
				counts = temp
			}

			await Promise.all(requests)
			this.folderCounts = counts
		},
		configureMenuSettings(menu) {
			openSettings('navigation-menu', { menu })
		},
	},
}
</script>

<style lang="scss">
.navigation-items {
	padding-top: 16px;
	.button-container {
		text-align: center;
		margin-bottom: 8px;
	}
	.button-container,
	ul {
		min-width: 240px;
	}
	.icon {
		width: 20px;
		font-size: 18px;
		opacity: 0.5;
	}

	ol,
	ul {
		margin-bottom: 20px;
	}

	li {
		position: relative;
		display: block;
		white-space: nowrap;
		margin-bottom: 2px;
	}

	.child-link {
		font-size: 0.9em;
		font-weight: normal;
		padding: 0.5em 1.5em 0.5em 48px;
		justify-content: space-between;
		.count {
			font-size: 0.9em;
		}
		&.grandchild {
			padding-left: 60px;
		}
		&.drop-target {
			transition: background 0.1s ease-in-out;
			background: var(--menu-item-hover-bg);
			& > * {
				pointer-events: none;
			}
			&.drag-over {
				background: var(--menu-item-active-bg);
			}
		}
	}

	ul a {
		color: var(--nav-link);
		padding: 0.9em 1.5em 0.9em 48px;
		font-weight: 500;
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-left: 4px solid transparent;
		user-select: none;

		& > .icon {
			margin-left: -30px;
			margin-right: 8px;
		}

		button.configure-menu {
			position: absolute;
			right: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 100%;
			&:not(:hover) {
				opacity: 0.3;
			}
		}

		&:hover {
			background: var(--nav-link-hover);
			&:not(.child-link) {
				border-left: 4px solid var(--tertiary-border);
			}
		}

		&.active {
			> .icon {
				opacity: 1;
			}
			&:not(.child-link) {
				color: var(--nav-link-active);
				border-left: 4px solid var(--nav-link-active);
			}
			&.child-link {
				font-weight: 600;
			}
		}

		&.coming-soon::after {
			position: absolute;
			left: 39px;
			bottom: 3px;
			font-size: 10px;
			content: 'Coming Soon';
			color: var(--info-color);
		}
	}

	.nav-repositoryreview .count {
		display: none;
	}
}
</style>
