<template>
	<div class="persistent-drawer">
		<div class="persistent-drawer-main">
			<slot></slot>
		</div>
		<div class="persistent-drawer-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PersistentNavigationDrawer',
}
</script>

<style lang="scss" scoped>
.persistent-drawer {
	position: relative;
	background: var(--secondary-bg);
	border-right: 1px solid var(--secondary-border);
	& select {
		width: 200px;
		padding: 5px;
		margin: 5px 10px;
	}
	display: flex;
	flex-direction: column;
	align-items: stretch;
	z-index: 2;
	overflow-y: overlay; // needed for consistent scrollbars in Chrome and Edge
	overflow-x: hidden;
	flex-shrink: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.persistent-drawer-main {
	flex-grow: 1;
}
.persistent-drawer-footer {
	flex-grow: 0;
	flex-shrink: 0;
}
.stop-drawer-anim .persistent-drawer {
	transition: none !important;
	animation: none !important;
}
</style>
