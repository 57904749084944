













































































































import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import UploadForm from '@components/UploadForm.vue'
import UploadStudyForm from '@components/UploadStudyForm.vue'
import { uploadData } from '@services/uploads'

import api from '@services/api'
import { dlg } from '@utils/dlgUtils'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { confirmAttachmentFileSize } from './AttachmentFileSize.vue'

const UPLOAD_CONTEXT = 'create-study'

const CreateStudyDlg = {
	name: 'CreateStudyDlg',
	components: {
		DlgHeader,
		ModalWrapper,
		UploadForm,
		UploadStudyForm,
		UploadItemFile: () => import(/* webpackChunkName: "componentUploadItemFile" */ '@components/UploadItemFile.vue'),
	},
	data() {
		return {
			imageViews: [],
			dropTarget: undefined,
			showMetadataForm: false,
			form: {},
			canSubmitForm: false,
			isSubmitting: false,
			uploadData,
		}
	},
	computed: {
		excludedFiles() {
			return this.uploadData.excludedFiles.filter(upload => upload.context === UPLOAD_CONTEXT)
		},
		pendingUploads() {
			return this.uploadData.uploads.filter(upload => upload.context === UPLOAD_CONTEXT && !upload.batchId)
		},
		hasImages() {
			return this.pendingUploads.some(u => u.isImage)
		},
	},
	async created() {
		this.imageViews = await api.clinic.getImageViewMeta()
	},
	mounted() {
		this.dropTarget = this.$refs.dropTarget
	},
	methods: {
		cancelUpload(upload) {
			uploadData.CANCEL_UPLOAD(null, upload.file)
		},
		clearExcludedFiles() {
			uploadData.CLEAR_UPLOAD_EXCLUDED_FILES(UPLOAD_CONTEXT)
		},
		getValidImageViewsForUpload(upload) {
			return this.imageViews.filter(i => {
				// do not show image-only options (e.g. Abdomen Lateral) for attachments
				if (upload.isAttachment && !i.isAttachment) return false
				// if imageView does not have an extension whitelist, do not filter it out
				if (!i.extensions.length) return true
				// if imageView has extension list, make sure pending upload's extension is included
				const getExtension = file =>
					file.name
						.split('.')
						.pop()
						.toLowerCase()
				return i.extensions.includes(getExtension(upload.file))
			})
		},
		async close() {
			if (!this.isSubmitting) {
				const warning = 'Are you sure you want to cancel this new study?'
				if (this.pendingUploads.length && !(await showConfirm(warning))) return
				uploadData.CANCEL_PENDING_UPLOADS(UPLOAD_CONTEXT)
			}
			uploadData.CLEAR_UPLOAD_EXCLUDED_FILES(UPLOAD_CONTEXT)
			dlg.close(this, true, this.isSubmitting)
		},
		createDateString(date) {
			// create ISO 8601 date string without timezone offset
			if (!date || isNaN(date.getTime())) return
			const dateObject = new Date(date)
			return dateObject.toISOString().replace('Z', '')
		},
		async submit() {
			if (!this.canSubmitForm) return
			this.isSubmitting = true
			const pendingAttachmentFiles = this.pendingUploads.filter(u => !u.isImage).map(u => u.file)
			if (!(await confirmAttachmentFileSize(0, pendingAttachmentFiles))) {
				this.isSubmitting = false
				return
			}
			if (!this.hasImages) {
				// set attachment-only study's modality to OT
				const otherModality = this.$store.state.static.modalities.find(m => m.name === 'OT')
				this.form.modalityId = otherModality.id
			}
			try {
				let study = {
					...this.form,
					patientBirthDate: this.createDateString(this.form.patientBirthDate),
				}
				await uploadData.startUpload(UPLOAD_CONTEXT, study)
				this.close()
			} finally {
				this.isSubmitting = false
			}
		},
	},
}

export default CreateStudyDlg

export function openCreateStudyDlg() {
	return dlg.open(CreateStudyDlg, {})
}
