<template>
	<ast-toolbar-dropdown class="help-btn" icon="help-circle" title="Help" :items="completeList" />
</template>

<script>
import AstToolbarDropdown from '@components/ToolbarDropdown.vue'
import { mapState } from 'vuex'
import { openReportIssue } from '@dialogs/ReportIssue.vue'
import { openAboutOmniDlg } from '@dialogs/AboutOmni.vue'
import { openAboutCommunityDlg } from '@dialogs/AboutCommunity.vue'
import { openReleaseNotes } from '@dialogs/ReleaseNotes.vue'
import { clinicAPI } from '@services/clinicAPI'

export default {
	name: 'HelpMenu',
	components: { AstToolbarDropdown },
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			clinicAPI,
		}
	},
	computed: {
		...mapState({
			claims: state => state.auth.claims,
		}),
		completeList() {
			return [
				{
					iconName: 'server-network',
					label: `Connection: Keystone (${this.clinicAPI.importMachineName})`,
					canShow: () => this.clinicAPI.activeClinicUrl,
					noAction: true,
				},
				{
					iconName: 'cloud',
					label: 'Connection: Cloud',
					canShow: () => !this.clinicAPI.activeClinicUrl,
					noAction: true,
				},
				{
					iconName: 'exclamation-triangle',
					label: 'Report Issue',
					command: () => {
						const clinicCode = this.$store.getters.isAuthenticated
							? this.claims.activeClinicCode
							: this.$route.query.clinicCode
						openReportIssue({ clinicCode })
					},
				},
				...this.items,
				{
					iconName: 'book',
					label: 'User Guide',
					canShow: () => this.claims.isCommunityUser,
					command: () => {
						window.open('https://www.manula.com/manuals/asteris/asteris-keystone-community-user-guide/1/en/topic/about')
					},
				},
				{
					iconName: 'star',
					label: "What's New",
					command: () => openReleaseNotes('ALL'),
				},
				{
					iconName: 'info-circle',
					label: 'About Omni',
					canShow: () => !this.claims.isCommunityUser,
					command: () => openAboutOmniDlg(),
				},
				{
					iconName: 'info-circle',
					label: 'About Community',
					canShow: () => this.claims.isCommunityUser,
					command: () => openAboutCommunityDlg(),
				},
			]
		},
	},
}
</script>
