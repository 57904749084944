<template>
	<main class="list-layout">
		<ast-toolbar :is-primary="true">
			<div class="left">
				<ast-toolbar-branding v-if="!isDesktop" />
				<ast-toolbar-button
					v-if="!$route.meta.hideMenu"
					icon="menu"
					title="Menu"
					@click.native="$store.dispatch('toggleDrawers')"
				/>
				<ast-toolbar-button v-if="$route.meta.showBack" icon="arrow-left" title="Back" @click.native="$router.go(-1)" />
				<h3 id="pageTitle">{{ listName }}</h3>
			</div>
			<div class="right">
				<ast-toolbar-button
					v-if="activeClinic && clinics.length > 1 && !$route.meta.disableSwitchClinics"
					:label="activeClinic.name"
					title="Change clinic"
					class="clinic-dropdown"
				>
					<template #dropdown="{ hide }">
						<ast-toolbar-menu :items="clinics" @select="e => updateClinic(e, hide)">
							<template #item="{ item: clinic }">
								{{ clinic.name }}
							</template>
						</ast-toolbar-menu>
					</template>
				</ast-toolbar-button>
				<help-menu />
				<user-menu />
			</div>
		</ast-toolbar>

		<div v-if="activeDicomServiceHosts.length" class="active-dicom-services">
			<svg-icon icon="cloud-sync" style="margin-right: 12px; font-size: 1.5em;" />
			{{ activeDicomServiceMessage }}
			&nbsp;
			<a v-if="showDicomServiceQueueLink" @click="openDicomServiceQueue">
				View queue &hellip;
			</a>
		</div>

		<div class="list-content" :class="{ 'stop-drawer-anim': $route.meta.hideMenu }">
			<!-- Disable CSS animations when hideMenu=true; prevents animations going from false->true -->
			<transition name="reveal-right" @after-enter="onDrawerTransition" @after-leave="onDrawerTransition">
				<persistent-navigation-drawer v-if="!$route.meta.hideMenu && mq.medium && isPersistentDrawerOpen">
					<list-navigation-items />
					<template #footer>
						<community-upsell-promo class="community-upsell-promo" is-closable />
					</template>
				</persistent-navigation-drawer>
			</transition>
			<!-- MAIN ROUTER VIEW -->
			<router-view />
		</div>

		<!-- NAVIGATION DRAWER -->

		<!-- PANELS -->
		<modal-drawer
			v-if="!mq.medium && !$route.meta.hideMenu && isModalDrawerOpen"
			@close="$store.commit('TOGGLE_MODAL_DRAWER', false)"
		>
			<list-navigation-items />
			<template #footer>
				<community-upsell-promo class="community-upsell-promo in-modal" is-closable />
			</template>
		</modal-drawer>
		<slot name="panels"></slot>

		<!-- DIALOGS -->
		<slot name="dialogs"></slot>

		<desktop-downloader />
	</main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AstToolbar from '@components/Toolbar'
import AstToolbarButton from '@components/ToolbarButton'
import AstToolbarBranding from '@components/ToolbarBranding'
import AstToolbarMenu from '@components/ToolbarMenu'
import ModalDrawer from '@components/ModalDrawer'
import PersistentNavigationDrawer from '@components/PersistentNavigationDrawer'
import ListNavigationItems from '@components/layout/ListNavigationItems'
import CommunityUpsellPromo from '@components/CommunityUpsellPromo'
import UserMenu from '@components/UserMenu.vue'
import { dicomServiceData } from '@services/dicomServiceData'
import HelpMenu from '@/components/HelpMenu.vue'
import DesktopDownloader from '@components/DesktopDownloader.vue'
import { omniDesktop } from '@/electron/omniDesktop'
import { openDicomServiceQueue } from '@dialogs/DicomServiceQueue.vue'
import { eventBus } from '@services/eventBus'

export default {
	name: 'ListLayout',
	components: {
		UserMenu,
		AstToolbar,
		AstToolbarButton,
		AstToolbarBranding,
		AstToolbarMenu,
		ModalDrawer,
		PersistentNavigationDrawer,
		ListNavigationItems,
		CommunityUpsellPromo,
		HelpMenu,
		DesktopDownloader,
	},
	provide() {
		return {
			$listLayout: this,
		}
	},
	data() {
		return {
			dicomServiceData,
			layout: this,
		}
	},
	computed: {
		...mapGetters(['activeClinic']),
		...mapState({
			clinics: state => state.static.clinics,
			isPersistentDrawerOpen: state => state.ui.isPersistentDrawerOpen,
			isModalDrawerOpen: state => state.ui.isModalDrawerOpen,
			claims: state => state.auth.claims,
		}),
		listName() {
			return this.$route.meta.title
		},
		activeDicomServiceHosts() {
			return dicomServiceData.activeDicomServiceHosts
		},
		activeDicomServiceMessage() {
			if (!this.activeDicomServiceHosts.length) return ''
			let message = 'Images are uploading from '
			const isLocalService =
				this.activeDicomServiceHosts.length === 1 &&
				this.activeDicomServiceHosts[0].toUpperCase() === window.ElectronInterface?.hostname
			if (isLocalService) {
				message += 'this PC'
			} else if (this.activeDicomServiceHosts.length <= 2) {
				message += this.activeDicomServiceHosts.join(' and ')
			} else {
				message += 'multiple devices'
			}
			message += '. Do not shutdown or disconnect '
			if (!isLocalService) {
				message += this.activeDicomServiceHosts.length === 1 ? 'that device ' : 'these devices '
			}
			message += 'at this time.'
			return message
		},
		showDicomServiceQueueLink() {
			return this.dicomServiceData.localService?.checkinMS < 60000
		},
		isDesktop() {
			return omniDesktop.isConnected
		},
	},
	methods: {
		openDicomServiceQueue,
		...mapActions(['switchClinic']),
		async updateClinic({ id }, hideDropdown) {
			if (!id || id === this.activeClinic.id) return
			hideDropdown()
			await this.switchClinic({ id })
		},
		toggleSettingsPanel() {
			let query = { ...this.$route.query }
			if (query.setting !== undefined) {
				delete query.setting
				this.$router.replace({ query })
			} else {
				query.setting = null
				this.$router.push({ query })
			}
		},
		onDrawerTransition() {
			eventBus.post('resize')
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.list-layout .page-wrapper {
	height: 100%;
}

main.list-layout {
	background: var(--secondary-bg);
	color: var(--primary-label);
	display: flex;
	flex-direction: column;
	height: 100%;
	font-weight: 400;
	.left,
	.right {
		min-width: 0;
		display: flex;
		align-items: center;
	}
	.left {
		flex-shrink: 0;
	}
	h3#pageTitle {
		flex-shrink: 1;
		min-width: 0;
		overflow: hidden;
		text-overflow: ellipsis; // in case of a long clinic name
	}
	@media (max-width: $mqSmall) {
		h3#pageTitle {
			display: none;
		}
	}
	.active-dicom-services {
		display: flex;
		align-items: center;
		padding: 8px 16px;
		background: var(--banner-info-bg);
		font-size: 0.85em;
		font-weight: bold;
	}
}
.toolbar-header-secondary {
	z-index: 1;
	display: flex;
	justify-content: space-around !important;
	width: 100%;
	border-bottom: 1px solid var(--divider);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	button .no-break {
		white-space: normal;
	}
}

@media (min-width: $mqSmall) {
	.toolbar-header-secondary {
		justify-content: flex-start !important;
	}
}

#pageTitle {
	margin: 0;
	margin-left: 1em;
}

.clinic-menu li:hover {
	background: var(--menu-item-hover-bg);
}

.list-content {
	display: flex;
	flex-grow: 1;
	min-height: 0;
	height: 100%; // IE
}

.list-scroll {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 16px 16px 8px 16px;
	height: 100%;
}

.placeholder {
	font-size: 1.2em;
	font-weight: 800;
	opacity: 0.75;
	padding: 1em;
	text-align: center;
}

.clinic-dropdown button .label {
	max-width: 50vw;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.community-upsell-promo {
	position: absolute;
	width: 100%;
	bottom: 0;
	&.in-modal .banner {
		background: var(--modal-drawer-bg);
	}
}

@media (max-width: $mqSmall) {
	.community-upsell-promo {
		display: none;
	}
	.list-scroll > .card,
	.list-scroll > div > .card {
		margin-right: -16px;
		margin-left: -16px;
		border-right-width: 0px !important;
		border-left-width: 0px !important;
	}
}
</style>
