<template>
	<banner
		v-if="$store.getters.isCommunityUser"
		id="community-upsell"
		v-bind="$attrs"
		learn-more-link="mailto:sales@asteris.com?subject=Community user interested in other Asteris Apps"
	>
		Asteris has other apps that can help you manage your images.
	</banner>
</template>

<script>
import Banner from './Banner.vue'

export default {
	name: 'CommunityUpsellPromo',
	components: {
		Banner,
	},
}
</script>
